<script lang="ts">
  import TextButton from '@svelte/components/atoms/button/text/TextButton.svelte';
  import Heading from '@svelte/components/atoms/typography/Heading/Heading.svelte';
  import Paragraph from '@svelte/components/atoms/typography/Paragraph/Paragraph.svelte';

  export let title: string;
  export let message: string;
  export let callbackText: string;
  export let callback: () => void;
</script>

<div class="flex flex-col items-center justify-center lg:absolute lg:inset-0">
  <Heading level={2} className="pb-rhythm0 text-center" withoutSpacing bold>
    {title}
  </Heading>
  <div class="flex flex-col items-center justify-center">
    <Paragraph measure="" className="max-w-md text-center">
      {message}
    </Paragraph>
    <TextButton
      className="max-w-xs"
      kind="secondary"
      on:click={callback}
      text={callbackText}
    />
  </div>
</div>
