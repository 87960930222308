<script context="module" lang="ts">
  const submittedHiddenClassNames = 'hidden lg:invisible lg:block';
</script>

<script lang="ts">
  import Container from '@svelte/components/atoms/layout/Container.svelte';
  import Message from './Message.svelte';
  import Heading from '@svelte/components/atoms/typography/Heading/Heading.svelte';
  import Paragraph from '@svelte/components/atoms/typography/Paragraph/Paragraph.svelte';
  import { makeClassNames } from 'lib/util';
  import { queryStore } from '@svelte/service/backend/queryStore';
  import { getContextGraphqlWorker } from '@svelte/service/worker/context/context';
  import NewsletterForm from '../../forms/NewsletterForm.svelte';
  import { ValidationWorker } from '@svelte/components/atoms/forms/validation/types';
  import { makeValidationWorker } from '@svelte/components/atoms/forms/validation';
  import { NewsletterFormState } from '../../forms/state';
  import { inview } from 'svelte-inview';
  import { getAppEventDispatcher } from '@svelte/lib/events/context';
  import { AppEventName } from 'lib/events/contracts';

  const queryWorker = getContextGraphqlWorker();

  export let formState: NewsletterFormState;
  export let validationWorker: ValidationWorker = makeValidationWorker();
  export let appEventDispatcher = getAppEventDispatcher();

  const userEmailQuery = queryStore({
    key: 'userEmail',
    variables: {},
    worker: queryWorker
  });
  let displayBanner: boolean | undefined;
  let newsletterForm: NewsletterForm;

  $: emailData = $userEmailQuery?.data?.currentUser;

  $: {
    // NOTE: Only run this block once after getting data once
    // by checking typeof displayBanner === 'undefined'
    // in order to not override hiding the banner
    // after user has subscribed and success state needs to render
    if (typeof displayBanner === 'undefined' && emailData) {
      if (emailData.confirmedNewsletter) {
        displayBanner = false;
      } else if (emailData.subscribedToNewsletterTime) {
        const fourHours = 4 * 60 * 60 * 1000;
        const subscribedInLast4Hours =
          new Date().getTime() -
            new Date(emailData.subscribedToNewsletterTime).getTime() <
          fourHours;
        displayBanner = !subscribedInLast4Hours;
      } else {
        displayBanner = true;
      }
    }
  }
</script>

{#if displayBanner}
  <div
    class="bg-secondary"
    use:inview={{ unobserveOnEnter: true }}
    on:inview_enter={() =>
      appEventDispatcher.dispatch(AppEventName.SawNewsletterBanner)}
  >
    <Container className="relative py-rhythm5 lg:flex lg:items-center">
      <div
        class={makeClassNames(
          formState !== NewsletterFormState.Ready && submittedHiddenClassNames,
          'lg:w-0 lg:flex-1'
        )}
      >
        <Heading
          level={2}
          color="text-primary"
          className="pb-rhythm0"
          withoutSpacing
          bold
        >
          20% Discount
        </Heading>

        <Paragraph>
          Sign up to our newsletter and let us take you on a journey towards
          responsible consumption of personal care products. We'll give you 20%
          off your next purchase as a thank you.
        </Paragraph>
      </div>
      <div
        class={makeClassNames(
          formState !== NewsletterFormState.Ready && submittedHiddenClassNames,
          'mt-rhythm3 lg:ml-rhythm3 lg:mt-0 lg:flex-1'
        )}
      >
        <NewsletterForm
          bind:formState
          bind:this={newsletterForm}
          {validationWorker}
        />
      </div>
      {#if formState === NewsletterFormState.Success}
        <Message
          title="Subscribed!"
          message="Just one more thing. Please go to your inbox and confirm your subscription."
          callbackText="Close This Banner"
          callback={() => {
            displayBanner = false;
          }}
        />
      {:else if formState === NewsletterFormState.Error}
        <Message
          title="Oops!"
          message="Our apologies, but something is not working. Please try again or contact us directly."
          callbackText="Try Again"
          callback={newsletterForm.tryAgain}
        />
      {/if}
    </Container>
  </div>
{/if}
